.App {
  background-color: white;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.widthNo {
  width: 4%;
  max-width: 20px;
}

.width2 {
  width: 2%;
  align-items: left;
}

.width3 {
  width: 3%;
  align-items: left;
}

.width4 {
  width: 4%;
  align-items: left;
}

.width5 {
  width: 5%;
  align-items: left;
}

.width6 {
  width: 6%;
  align-items: left;
}

.width7 {
  width: 7%;
  align-items: left;
}

.width8 {
  width: 8%;
  align-items: left;
}

.width10 {
  width: 10%;
  align-items: left;
}

.width12 {
  width: 12%;
  align-items: left;
}

.width15 {
  width: 15%;
  align-items: left;
}

.width20 {
  width: 20%;
  align-items: left;
}

.form-controlPLD {
  margin-right: 10px;
}

/*-------- Login in -------*/

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: rgb(135, 150, 167, 100);
  color: floralwhite;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
}

.btn-submit {
  color: floralwhite;
  background-color: #61CFA1;
  margin-bottom: 15px;
}

.btn-submit:hover {
  color: floralwhite;
  background-color: #5d9b82;
}

.forgot-link {
  color: floralwhite;
  text-align: right;
  display: block;
  margin-top: 5px;
}

.forgot-link:hover {
  text-decoration: underline;
}

.password-input {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-toggle img {
  width: 60%;
  height: auto;
}

@media (max-width: 768px) {
  .Auth-form-title {
    font-size: 1em;
  }

  .Auth-form-container {
    padding: 40px;
  }

  .Auth-form-content {
    padding-left: 10%;
    padding-right: 10%;
  }

  .form-control {
    height: 30px;
    font-size: small;
  }

  .btn-submit {
    height: 30px;
    font-size: small;
    color: floralwhite;
    background-color: #61CFA1;
  }

  .btn-submit:hover {
    color: floralwhite;
    background-color: #5d9b82;
  }

  input, select, textarea {
    font-size: medium !important;
  }
}

/*-------- tracking -------*/
.leaflet-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.leaflet-control-container {
  position: fixed;
  pointer-events: none;
  z-index: 400;
  height: 100vh;
  width: 100vw;
  top: 50px;
}

.leaflet-control-attribution {
  position: absolute;
  bottom: 50px;
}

.leaflet-control-layers-list {
  width: 70px;
  height: 40px;
  font-size: 12px;
}

.leaflet-control-layers-selector {
  height: auto;
}

.checkbox-item {
  height: auto;
}

.btn-viewAll {
  position: absolute;
  bottom: 20px;
  right: 10px;
  height: 30px;
  font-size: 12px;
  color: floralwhite;
  background-color: rgb(128, 145, 164);
}

.btn-viewAll:hover {
  color: floralwhite;
  background-color: rgba(128, 145, 164, 0.45);
}

.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 0px 10px 0px 10px;
  background-color: rgb(128, 145, 164);
  height: 45px;
}

.nav-link {
  color: #ffffff;
}

.nav-link:hover {
  color: #cccccc;
}

.navbar-brand {
  font-size: 15px;
  color: #ffffff;
}

.navbar-toggler {
  padding: 0px 5px 0px 5px;
  border-color: #ffffff;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  width: 20px;
}

.offcanvas {
  background-color: rgb(128, 145, 164);
  color: #ffffff;
}

.pin-list {
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0px;
  z-index: 1000;
}

/* --------history---------------- */
.MuiInputBase-root {
  height: 30px;
  width: 190px;
  margin-top: 10px;
}
.MuiInputBase-input{
  height: 40px;
}

.MuiTextField-root {
  height: 40px;
  width: 190px;
}

.MuiFormLabel-root{
  margin-top: 10px;
}

.dateLable {
  height: 30px;
  font-size: 14px;
}

.react-datepicker-wrapper input {
  height: 25px;
  width: 120px;
  margin: 2px;
  font-size: 12px;
  text-align: center;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.btn-dropdown {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 30px;
  width: 85px;
  font-size: 12px;
  color: floralwhite;
  background-color: rgb(128, 145, 164);
}

.custom-dropdown-content {
  position: absolute;
  bottom: 0px;
  left: 10px;
  background-color: #f8f9fa;
  min-width: 180px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: none;
  font-size: 14px;
  display: block;
}


.btn-reset {
  position: relative;
  padding: 1px 5px;
  margin: 0px 0px 5px 0px;
  bottom: 0px;
  height: 20px;
  width: 75px;
  font-size: 12px;
  text-align: center;
  color: floralwhite;
  background-color: rgba(97, 107, 118, 0.45);
}

.btn-reset:hover {
  color: rgb(128, 145, 164);
  border-color: rgb(128, 145, 164);
}

.btn-search {
  position: relative;
  padding: 1px 5px;
  margin: 0px 5px 5px 0px;
  bottom: 0px;
  height: 20px;
  width: 75px;
  font-size: 12px;
  text-align: center;
  color: floralwhite;
  background-color: rgb(128, 145, 164);
}

.btn-search:hover {
  color: floralwhite;
  background-color: rgba(128, 145, 164, 0.45);
}

.btn-download {
  min-width: 180px;
  height: 10px;  
  font-size: 10px;

}

.react-datepicker-wrapper {
  width: 90px;
}

/*---------addUser----------*/

.row {
  padding: 0px 10px;
}

.row h2 {
  margin-top: 80px;
}

.table-card {
  max-height: 400px;
  overflow-y: auto;
}

th {
  position: sticky;
  top: 0;
  background-color: white;
}

input {
  height: 30px;
}

textarea {
  height: 20px;
}

.form-select {
  height: 33px;
  font-size: smaller;
}

.checkbox-enabled {
  padding-left: 0%;
}

.card {
  margin: 10px 20px;
  border: 0px;
  width: 97%;
}

.btn {
  height: 30px;
  font-size: smaller;
  text-align: center;
  padding: 4px 12px;
}

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 18px;
  right: 5px;
  top: 5px;
}

/*------------AddAaylod------------*/

.paylod-row input {
  width: 40px;
}